import React from 'react'
import ShareInformationHome from '../templates/Share Information/ShareInformationHome'

function ShareInformation() {
  return (
    <div>
        <ShareInformationHome />
    </div>
  )
}

export default ShareInformation
