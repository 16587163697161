import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './VotingShareChart.css';
import Aos from "aos";
import "aos/dist/aos.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const VotingShareChart = () => {
  const [chart1Animation, setChart1Animation] = useState(false);
  const [chart2Animation, setChart2Animation] = useState(false);

  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);

  const data1 = {
    labels: ['12/13', '13/14', '14/15', '15/16', '16/17','17/18','18/19','19/20','20/21','21/22'],
    datasets: [
      {
        data: [15, 10, 20, 25, 22, 20, 10, 8, 50, 40],
        fill: false,
        backgroundColor: 'rgba(255, 255, 255)',
        borderColor: 'rgba(255, 255, 255)',
        tension: 0.4,
      },
    ],
  };

  const data2 = {
    labels: ['12/13', '13/14', '14/15', '15/16', '16/17','17/18','18/19','19/20','20/21','21/22'],
    datasets: [
      {
        data: [12, 8, 15, 18, 16, 15, 8, 6, 40, 28],
        fill: false,
        backgroundColor: 'rgba(255, 255, 255)',
        borderColor: 'rgba(255, 255, 255)',
        tension: 0.4,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 50,
        ticks: {
          stepSize: 10,
          font: { size: 10 },
        },
      },
    },
  };

  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 40,
        ticks: {
          stepSize: 10,
          font: { size: 10 },
        },
      },
    },
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5, 
    };

    const chart1Observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChart1Animation(true);
        }
      },
      observerOptions
    );

    const chart2Observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChart2Animation(true);
        }
      },
      observerOptions
    );

    if (chart1Ref.current) {
      chart1Observer.observe(chart1Ref.current);
    }

    if (chart2Ref.current) {
      chart2Observer.observe(chart2Ref.current);
    }

    return () => {
      if (chart1Ref.current) {
        chart1Observer.unobserve(chart1Ref.current);
      }
      if (chart2Ref.current) {
        chart2Observer.unobserve(chart2Ref.current);
      }
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="voting-share-charts-container">
      <div className="voting-share-lineChart" ref={chart1Ref} data-aos="fade-right" data-aos-duration="900">
        <div className="voting-share-voting-chart">
          <div className="voting-share-chart-container">
            <h1 className="voting-share-chart-container-h1">Voting Share Price</h1>
            <p>Past 10 Years</p>
            {chart1Animation && <Line data={data1} options={options1} />}
          </div>
        </div>
      </div>
      <div className="voting-share-lineChart" ref={chart2Ref} data-aos="fade-left" data-aos-duration="900">
        <div className="voting-share-voting-chart">
          <div className="voting-share-chart-container">
            <h1 className="voting-share-chart-container-h1">Non-Voting Share Price</h1>
            <p>Past 10 Years</p>
            {chart2Animation && <Line data={data2} options={options2} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotingShareChart;
