import React from 'react'
import ShareInformationMainBanner from './ShareInformationMainBanner'
import WeeklyVoting from './WeeklyVoting'
import MarketValue from './MarketValue'

function ShareInformationHome() {
  return (
    <>
      <ShareInformationMainBanner />
      <WeeklyVoting />
      <MarketValue />
    </>
  )
}

export default ShareInformationHome
