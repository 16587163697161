import React from 'react'
import './WeeklyVoting.css'
import WeeklyVotingGraph1 from './WeeklyVotingGraph1'

function WeeklyVoting() {
  return (
    <div className='weekly-voting-main-container'>
      <WeeklyVotingGraph1 />
    </div>
  )
}

export default WeeklyVoting
