import React,{ useEffect } from 'react'
import './ShareInformationMainBanner.css'
import ShareInformationImage from "../../images/ShareInformationMainBanner.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function ShareInformationMainBanner() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  return (
    <div>
      <div>
        <div className="share-information-page">
          <img className="share-information-home-image" alt="" src={ShareInformationImage} />
          <div className="share-information-image-text-box">
            <div className="share-information-image-background-text">
              <h1 className="share-information-image-topic">
                Share Information
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
