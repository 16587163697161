import React, { useState } from "react";
import "./MarketValue.css";
import VotingShareChart from "./VotingShareChart";

function MarketValue() {
  return (
    <div className="market-value-main-container">
      <div
        className="market-value-sub-container"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <div className="market-value-sub-container-header">
          <h3>Market Value of Voting Share Price</h3>
          <p>Last 5 Years</p>
        </div>
        <div className="market-value-table-container">
          <table>
            <thead>
              <tr>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  FY 2017/18
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  FY 2018/19
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  FY 2019/20
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  FY 2020/21
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  FY 2021/22
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <td>As at the end of the year</td>
                <td>58.90</td>
                <td>38.50</td>
                <td>35.00</td>
                <td>50.90</td>
                <td>38.10</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-once="true"
              >
                <td>Highest price traded</td>
                <td>90.00</td>
                <td>60.00</td>
                <td>64.40</td>
                <td>188.80</td>
                <td>71.00</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <td>Lowest price traded</td>
                <td>55.00</td>
                <td>36.00</td>
                <td>33.80</td>
                <td>36.00</td>
                <td>35.60</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <td>No. of shares</td>
                <td>72,900,00</td>
                <td>72,900,000</td>
                <td>72,900,000</td>
                <td>291,600,000</td>
                <td>291,600,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="market-value-sub-container"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <div className="market-value-sub-container-header">
          <h3>Market Value of Non-Voting Share Price</h3>
          <p>Last 5 Years</p>
        </div>
        <div className="market-value-table-container">
          <table>
            <thead>
              <tr>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  FY 2017/18
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  FY 2018/19
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  FY 2019/20
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="900"
                  data-aos-once="true"
                >
                  FY 2020/21
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  FY 2021/22
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <td>As at the end of the year</td>
                <td>40.50</td>
                <td>25.60</td>
                <td>28.90</td>
                <td>42.40</td>
                <td>25.00</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-once="true"
              >
                <td>Highest price traded</td>
                <td>72.00</td>
                <td>43.00</td>
                <td>50.90</td>
                <td>157.50</td>
                <td>54.00</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <td>Lowest price traded</td>
                <td>40.00</td>
                <td>25.10</td>
                <td>24.00</td>
                <td>25.00</td>
                <td>24.90</td>
              </tr>
              <tr
                data-aos="fade-up"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <td>No. of shares</td>
                <td>21,870,000</td>
                <td>21,870,000</td>
                <td>21,870,000</td>
                <td>87,480,000</td>
                <td>87,480,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="voting-share-main-container">
        <VotingShareChart />
      </div>
    </div>
  );
}

export default MarketValue;
